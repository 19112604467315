.footer-container{

    position: relative;

}

.footer-container>hr{

    border: 1px solid var(--lightgray);
}

.footer {
 
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 23rem;
}

.social-links{

    display: flex;
    gap: 4rem;
}

.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transform: scale(1.4);
}


.logo-f>img{
    width: 10rem;
}


.blur-f-1{
    top: 205rem;
    right: 15rem;
    width: 35rem;
    height: 15rem;
    filter: blur(200px);
    background: red;
}

.blur-f-2{
    top: 205rem;
    left: 15rem;
    width: 30rem;
    height: 15rem;
    filter: blur(150px);
    
}