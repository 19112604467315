.plans-container{

    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    margin-top: -4rem;
    gap: 4rem;
    position: relative;

}

.plans{
    display: flex;
    gap: 5rem;
    justify-content: center;
    align-items: center;
}

.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    color: white;

}

.plan:nth-child(2)
{
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan>:nth-child(2)
{
    font: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3)
{
    font-size: 3rem;
    font-weight: bold;

}

.plan>:nth-child(5){

    font-size: 0.8rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img{
    width: 1rem;

}

.plans>:nth-child(2)>svg{
    fill: white;
}


.plans>:nth-child(2)>button{

color: orange;
}

.blur-p-1{
    width: 35rem;
    height: 33rem;
    top: 6rem;
    left: 0rem;
}

.blur-p-2{
    width: 33rem;
    height: 33rem;
    right: 6rem;
    top: -10rem;
}


@media screen and (max-width: 480px){


    .plans{
        flex-direction: column;
    }

        .plans>:nth-child(2){
            transform: none;
        }

        


}
