.header{
    display: flex;
    justify-content: space-between;
}

.logo{

    width: 16rem;
    height: 8rem;
    
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}


.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer;
}

@media screen and (max-width: 480px){


            .logo{
                width:27rem;
                height: 12rem;

            }

            .header>:nth-child(2)
            {
                position: fixed;
                right: 2rem;
                z-index: 99;
            }

            .header-menu{
                flex-direction: column;
                background-color: var(--appColor);
                padding: 1rem;
            }

}