.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
        
}

.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.left-t>:nth-child(1)
{
    color: orange;
    font-weight: bold;
}

.left-t>:nth-child(2),.left-t>:nth-child(3)

{
    font-size: 3rem;
    font-weight: bold;
}

.left-t>:nth-child(4)
{

    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    list-style: 40px;
}

.right-t{
    flex: 1px;
    position: relative;
}


.right-t>img{
    position: absolute;
    width: 21rem;
    height: 21rem;
    object-fit: cover;
    right: 10rem;
    top: 0rem;
}

.right-t>:nth-child(1){
    position: absolute;
    width: 22rem;
    height: 20rem;
    border: 2px  solid orange;
    background-color: transparent;
    right: 10rem;
    bottom: 4rem;
}


.right-t>:nth-child(2){
    position: absolute;
    width: 20rem;
    height: 21rem;
    background-color: orange;
    right: 8rem;
    bottom: 1rem;
}

.arrow{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}

.arrow>img{
    cursor: pointer;
    width: 1.5rem;
}

.arrow>img:hover{
    transform: scale(1.1);
    
}




@media screen and (max-width: 480px){

   .testimonials
   {
        flex-direction: column;
   }


    .left-t>:nth-child(2),
    .left-t>:nth-child(3)
    {
        font-size: xx-large;
    }

    .right-t{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    .right-t > div{
        position: relative;
        display: none;
    }

    .right-t>img{
        top: 0;
        right: 0;
        position: relative;
        align-self:center;
        width: 17rem;
    }
    .right-t>:last-child{
        display: block;
        bottom: 0;
        left: 0;
    }


}